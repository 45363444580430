import { fontSize } from 'composable/chakra/figma-tokens'
import { colors } from 'composable/chakra/theme/foundations/colors'

const blockquoteBorderColor = colors.text.muted

const baseStyles = {
  blockquote: {
    borderLeft: `4px solid ${blockquoteBorderColor}`,
    margin: '40px 0',
    padding: '0 0 0 24px',
    fontSize: '18px',
  },

  h1: {
    fontSize: fontSize['9xl'],
    fontWeight: 'bold',
    lineHeight: '1.2',
    margin: '0 0 2.5rem 0',
  },

  h2: {
    fontSize: fontSize['7xl'],
    fontWeight: 'bold',
    lineHeight: '1.3',
    margin: '0 0 2.5rem 0',
  },

  h3: {
    fontSize: fontSize['4xl'],
    fontWeight: 'bold',
    lineHeight: '1.4',
    margin: '0 0 2rem 0',
  },

  h4: {
    fontSize: fontSize['3xl'],
    fontWeight: 'bold',
    lineHeight: '1.5',
    margin: '0 0 1.5rem 0',
  },

  h5: {
    fontSize: fontSize['xl'],
    fontWeight: 'bold',
    lineHeight: '1.6',
    margin: '0 0 1.25rem 0',
  },

  h6: {
    fontSize: fontSize['md'],
    fontWeight: 'bold',
    lineHeight: '1.7',
    margin: '0 0 1.25rem 0',
  },

  p: {
    fontSize: fontSize['xl'],
    marginBottom: '1rem',
  },

  ol: {
    padding: '0 0 0 24px',
    listStyleType: 'decimal',
  },

  'ol ol': {
    listStyleType: 'lower-alpha',
  },

  'ol ol ol': {
    listStyleType: 'lower-roman',
  },

  ul: {
    padding: '0 0 0 24px',
    listStyleType: 'disc',
  },

  'ul ul': {
    listStyleType: 'circle',
  },

  'ul ul ul': {
    listStyleType: 'square',
  },

  li: {
    fontSize: '16px',
    lineHeight: '1.6',
    margin: '12px 0',
    padding: '0 0 0 8px',
  },

  a: {
    textDecoration: 'underline',
  },

  'p a:only-child': {
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
    color: colors.text.link,
  },

  'img.content-image': {
    height: 'auto',
    display: 'block',
    margin: '0 auto',
    border: '2px solid red',
  },

  'img.content-image-inline': {
    display: 'inline-block',
  },

  figcaption: {
    display: 'block',
    textAlign: 'center',
    marginTop: '0.5rem',
    fontSize: '14px',
    color: '#666666',
  },

  'figure.content-image': {
    clear: 'both',
  },

  hr: {
    marginTop: '40px',
    marginBottom: '40px',
  },

  'hr + *': {
    marginTop: 0,
  },

  '* + hr': {
    marginBottom: 0,
  },
}

export const banner_DEFAULT_CSS_DESKTOP = {
  ...baseStyles,

  // Table and its components
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
    border: '1px solid #E7EAED',
    borderRadius: '12px',
    padding: '12px',
    margin: '0 0 24px 0',
  },

  thead: {
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#6b7b8e',
    backgroundColor: 'white',
  },

  tbody: {
    'tr:nth-of-type(odd)': {
      backgroundColor: '#f7f7f7',
    },
    'tr:nth-of-type(even)': {
      backgroundColor: 'white',
    },
    'tr:only-child': {
      backgroundColor: 'white',
    },
  },

  td: {
    padding: '16px 24px',
    textAlign: 'left',
  },

  th: {
    textAlign: 'left',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    padding: '16px 24px',
  },

  'th p': {
    margin: '0',
  },

  'check-list': {
    display: 'flex',
    alignItems: 'center',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  column: {
    flex: '1',
  },

  'grid-container': {
    display: 'grid',
  },

  'grid-child': {
    gridArea: 'auto',
  },

  span: {},

  div: {},

  iframe: {
    width: '100%',
    height: '400px',
  },
}

export const banner_DEFAULT_CSS_MOBILE = {
  ...baseStyles,

  h1: {
    fontSize: fontSize['7xl'],
    fontWeight: 'bold',
    lineHeight: '1.2',
    margin: '0 0 2.5rem 0',
  },

  h2: {
    fontSize: fontSize['6xl'],
    fontWeight: 'bold',
    lineHeight: '1.3',
    margin: '0 0 2.5rem 0',
  },

  table: {
    display: 'block',
    width: '100%',
    fontSize: '14px !important',
  },
  thead: {
    display: 'none',
  },

  tbody: {
    display: 'flex',
    flexDirection: 'column',
  },

  tr: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    borderLeft: '1px solid #E7EAED',
    borderRight: '1px solid #E7EAED',
    borderTop: '0',
    borderBottom: '1px solid #9DA2A8',
    backgroundColor: '#fff',
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f7f7',
    },
  },

  'tr:first-child': {
    borderTop: '1px solid #E7EAED',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },

  'tr:last-child': {
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    borderBottom: '1px solid #E7EAED',
  },

  'tr:only-child': {
    backgroundColor: 'white',
  },

  td: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem',
  },

  'td:not(:last-child)': {
    borderBottom: '1px solid #eee',
  },

  '.mobile-table-header': {
    fontWeight: 'bold',
    flexBasis: '50%',
    textAlign: 'left',
  },

  '.mobile-table-data': {
    flexBasis: '50%',
    textAlign: 'left',
  },

  'table:not(:has(thead)) .content-image': {
    width: '100%',
    height: 'auto',
    display: 'block',
    margin: '0 auto',
  },

  'table:has(thead) .content-image': {
    width: '50%',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: '0',
  },

  'table.with-header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  'table.with-header .content-image': {
    width: '50%',
    alignSelf: 'flex-end',
    margin: '0 0 1rem 0',
    border: '2px solid blue',
  },

  '.black-box-placeholder': {
    display: 'none',
  },

  p: {
    fontSize: '18px',
    marginBottom: '1rem',
  },
}

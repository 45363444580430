import { WithCSSVar } from '@chakra-ui/react'
import { Dict } from '@chakra-ui/utils'
import { convertRemToPx } from 'composable/chakra/utils/resolveSizeUnit'
import min from 'lodash/min'

export const getScreenWidthWithBreakpointLimits = (
  breakpoints: WithCSSVar<Dict>,
  containerSize: string,
  screenWidth: number,
): number => {
  let screenWidthLimited
  switch (containerSize) {
    case 'full':
      screenWidthLimited = screenWidth
      break
    case '3xl':
      screenWidthLimited = min([screenWidth, convertRemToPx(breakpoints['3xl'])])
      break
    case 'xl':
      screenWidthLimited = min([screenWidth, convertRemToPx(breakpoints['xl'])])
      break
    case 'lg':
      screenWidthLimited = min([screenWidth, convertRemToPx(breakpoints['lg'])])
      break
    default:
      screenWidthLimited = convertRemToPx(breakpoints['lg'])
  }
  return screenWidthLimited
}

/**
 * Ensures that the given dimension value (like width, height, font-size, etc.) has a size unit (px or rem).
 * If the dimension doesn't end with 'px' or 'rem', it appends 'px' by default.
 *
 * @param dimension - A string representing the dimension value.
 * @returns The dimension with a guaranteed size unit.
 */
export const resolveSizeUnit = (dimension: string): string => {
  if (!dimension || typeof dimension !== 'string' || isNaN(parseFloat(dimension))) return dimension

  if (dimension.endsWith('px') || dimension.endsWith('rem')) {
    return dimension
  }

  return `${dimension}px`
}

export const convertRemToPx = (value) => {
  const remValue = parseFloat(value)
  return remValue * 16
}
